<template>
  <select
    class="select-component"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)">
    <option v-for="(option, index) in options" :value="option.value" :key="index">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  props: ['modelValue', 'options'],
};
</script>

<style scoped>
.select-component {
  width: 100%;
  border: 1px dotted #e9bc96;
  padding: 14px 12px 12px 12px;
  font-family: inherit;
}
</style>
