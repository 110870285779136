<template>
  <div class="nav">
    <a href="https://www.amorino.com/es-mx">
      <img class="logo" src="/logo.svg" alt="Amorino Logo">
      <img class="logo-mobile" src="/logo-mini.svg" alt="Amorino Logo">
    </a>
    <div class="nav-links">
      <a class="nav-link" href="https://www.amorino.com/es-mx/myfavoritegelato">
        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjg4OTQgMS4xNzQzNkMxMi4xNjQ3IDAuNDE3MDc1IDExLjE3MDIgMCAxMC4wODkxIDBDOS4yODA5NiAwIDguNTQwODYgMC4yNDYxNSA3Ljg4OTMxIDAuNzMxNTU0QzcuNTYwNTUgMC45NzY1NzIgNy4yNjI2NSAxLjI3NjM0IDcgMS42MjYyMUM2LjczNzQ2IDEuMjc2NDQgNi40Mzk0NSAwLjk3NjU3MiA2LjExMDU4IDAuNzMxNTU0QzUuNDU5MTQgMC4yNDYxNSA0LjcxOTA0IDAgMy45MTA5IDBDMi44Mjk3NiAwIDEuODM1MjQgMC40MTcwNzUgMS4xMTA1MiAxLjE3NDM2QzAuMzk0NDU1IDEuOTIyNzkgMCAyLjk0NTI1IDAgNC4wNTM1NEMwIDUuMTk0MjUgMC40NDEyMzggNi4yMzg0MyAxLjM4ODU1IDcuMzM5NzNDMi4yMzU5OSA4LjMyNDg0IDMuNDUzOTYgOS4zMjQ4NyA0Ljg2NDQxIDEwLjQ4MjlDNS4zNDYwMiAxMC44NzgzIDUuODkxOTQgMTEuMzI2NiA2LjQ1ODc5IDExLjgwNDFDNi42MDg1NCAxMS45MzA0IDYuODAwNjkgMTIgNyAxMkM3LjE5OTIgMTIgNy4zOTE0NiAxMS45MzA0IDcuNTQxIDExLjgwNDNDOC4xMDc4NSAxMS4zMjY3IDguNjU0MDggMTAuODc4MiA5LjEzNTkxIDEwLjQ4MjZDMTAuNTQ2MSA5LjMyNDc3IDExLjc2NDEgOC4zMjQ4NCAxMi42MTE2IDcuMzM5NjJDMTMuNTU4OSA2LjIzODQzIDE0IDUuMTk0MjUgMTQgNC4wNTM0NEMxNCAyLjk0NTI1IDEzLjYwNTUgMS45MjI3OSAxMi44ODk0IDEuMTc0MzZaIiBmaWxsPSIjRTlCQzk2Ii8+Cjwvc3ZnPgo=">
        My favorite gelato
      </a>
      <a class="nav-link" href="https://club.amorinogelato.com">
        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0Ljk2MDkgNS4yOTA0M0MxNC44NjI3IDQuOTk0NjIgMTQuNTkzMyA0Ljc4NDUyIDE0LjI3NDYgNC43NTY1NEw5Ljk0NTE1IDQuMzczNjhMOC4yMzMxOSAwLjQ3MTE0QzguMTA2OTYgMC4xODUxMzUgNy44MTk0NyAwIDcuNTAwMDYgMEM3LjE4MDY1IDAgNi44OTMxNyAwLjE4NTEzNCA2Ljc2NjkzIDAuNDcxODA5TDUuMDU0OTcgNC4zNzM2OEwwLjcyNDg4NCA0Ljc1NjU0QzAuNDA2NzMxIDQuNzg1MTkgMC4xMzgwMTcgNC45OTQ2MiAwLjAzOTI1MjMgNS4yOTA0M0MtMC4wNTk1MTI2IDUuNTg2MjUgMC4wMzE2OTkgNS45MTA3IDAuMjcyMzc0IDYuMTE1MjNMMy41NDQ4OSA4LjkxMDQyTDIuNTc5OSAxMy4wNTA0QzIuNTA5MjkgMTMuMzU0OCAyLjYzMDYgMTMuNjY5NCAyLjg4OTkzIDEzLjg1MkMzLjAyOTMyIDEzLjk1MDEgMy4xOTI0IDE0IDMuMzU2ODYgMTRDMy40OTg2NSAxNCAzLjYzOTMxIDEzLjk2MjggMy43NjU1NCAxMy44ODkyTDcuNTAwMDYgMTEuNzE1NEwxMS4yMzMyIDEzLjg4OTJDMTEuNTA2NCAxNC4wNDkzIDExLjg1MDcgMTQuMDM0NyAxMi4xMDk1IDEzLjg1MkMxMi4zNjg5IDEzLjY2ODkgMTIuNDkwMSAxMy4zNTQxIDEyLjQxOTUgMTMuMDUwNEwxMS40NTQ1IDguOTEwNDJMMTQuNzI3MSA2LjExNTc5QzE0Ljk2NzcgNS45MTA3IDE1LjA1OTYgNS41ODY4IDE0Ljk2MDkgNS4yOTA0M1oiIGZpbGw9IiNFOUJDOTYiLz4KPC9zdmc+Cg==">
        Amorino Club
      </a>
      <a class="order-link" href="https://www.amorinogelato.com">
        <div class="order-icon">
          <img src="/vespa.svg" alt="">
        </div>
        Ordenar
      </a>
    </div>
  </div>
  <div class="container-wrapper">
    <div class="bg"></div>
    <div class="container">
      <div class="row">
        <div class="column image">
          <img class="ticket" src="/ticket.png" alt="">
        </div>
        <div class="column">
          <h1>
            Facturación
          </h1>
          <StepsControl class="steps" :now-step="step" :step-list="stepNames" />
          <div class="form" v-if="step === 1">
            <LoadWrapper :loading="loading">
              <Label
                class="label"
                label="Número de factura"
                v-slot='{ hideError }'
                :errors="getErrors('ticketId')" >
                <Input v-model="ticketId" @input="hideError" />
              </Label>
              <Label
                class="label"
                label="RFC"
                v-slot='{ hideError }'
                :errors="getErrors('rfc')" >
                <Input v-model="rfc" @input="hideError" />
              </Label>
            </LoadWrapper>
            <div class="general-error" v-if="error">
              Error: {{ error }}
            </div>
            <input :class="{ disabled: loading }" type="submit" class="submit" value="Siguiente paso" @click="getInvoiceData">
          </div>
          <div class="form" v-if="step === 2">
            <LoadWrapper :loading="loading">
              <div class="ticket-data-items">
                <div class="ticket-data-item">
                  <div class="ticket-data-number">
                    {{ ticketId }}
                  </div>
                  Número de ticket
                </div>
                <div class="ticket-data-item">
                  <div class="ticket-data-number" v-if="total">
                    ${{ total.toFixed(2) }}
                  </div>
                  Total
                </div>
              </div>
              <Label
                class="label"
                label="RFC"
                v-slot='{ hideError }'
                :errors="getErrors('rfc')" >
                <Input v-model="rfc" @input="hideError" />
              </Label>
              <Label
                class="label"
                label="Nombre o razón social (en mayusculas y sin el régimen societario)"
                v-slot='{ hideError }'
                :errors="getErrors('name')" >
                <Input v-model="name" @input="hideError" />
              </Label>
              <Label
                class="label"
                label="Email"
                v-slot='{ hideError }'
                :errors="getErrors('email')" >
                <Input v-model="email" @input="hideError" />
              </Label>
              <Label
                class="label"
                label="Código postal"
                v-slot='{ hideError }'
                :errors="getErrors('zipCode')" >
                <Input v-model="zipCode" @input="hideError" />
              </Label>
              <Label
                class="label"
                label="Uso del CFDI"
                v-slot='{ hideError }'
                :errors="getErrors('cfdiUse')" >
                <Select v-model="cfdiUse" :options="cfdiUses" @input="hideError" />
              </Label>
              <Label
                class="label"
                label="Régimen fiscal"
                v-slot='{ hideError }'
                :errors="getErrors('fiscalRegime')" >
                <Select v-model="fiscalRegime" :options="fiscalregimens" @input="hideError" />
              </Label>
              <div class="general-error" v-if="error">
                Error: {{ error }}
              </div>
            </LoadWrapper>
            <input :class="{ disabled: loading }" type="submit" class="submit" value="Generar factura" @click="createInvoice">
          </div>
          <div class="form" v-if="step === 3">
            <h1 style="font-size: 30px">
              Gracias
            </h1>
            Tu factura ha sido enviada a tu correo electrónico
            <div style="margin-bottom: 20px"/>
            <div :class="{ disabled: loading }" class="submit" @click="downloadInvoice('pdf')">
              Descargar PDF
            </div>
            <div style="margin-bottom:8px"/>
            <div :class="{ disabled: loading }" class="submit" @click="downloadInvoice('xml')">
              Descargar XML
            </div>
            <div style="margin-bottom: 8px"/>
            <a class="download-link" v-if="downloadContent" :href="downloadContent" :download="downloadFileName">
              Si tu archivo no se descarga automáticamente da click aquí
            </a>
          </div>
          <div class="contact">
            Si tienes dudas o problemas técnicos contáctanos a <a class="mail-link" href="mailto:clientes@amorinogelato.com">clientes@amorinogelato.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ky from 'ky';
import StepsControl from '@/components/StepsControl.vue';
import Label from '@/components/Label.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import LoadWrapper from '@/components/LoadWrapper.vue';
import cfdiUses from '@/assets/cfdiUse.json';
import fiscalregimens from '@/assets/fiscalregimens.json';

export default {
  name: 'App',
  data() {
    return {
      step: 1,
      stepNames: ['Datos de compra', 'Datos fiscales', 'Descarga tu factura'],
      errors: [],
      rfc: '',
      ticketId: '',
      total: null,
      cfdiId: null,
      zipCode: null,
      loading: false,
      error: null,
      name: '',
      email: '',
      cfdiUse: 'G03',
      fiscalRegime: null,
      downloadContent: null,
      downloadFileName: null,
      cfdiUses,
      fiscalregimens,
    };
  },
  components: {
    Label,
    Input,
    StepsControl,
    LoadWrapper,
    Select,
  },
  methods: {
    async getInvoiceData() {
      console.log('getInvoiceData');
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        const { name, email, total } = await ky.post(`${process.env.VUE_APP_BACKEND_URL}/get-invoice`, {
          json: {
            ticketId: this.ticketId,
            rfc: this.rfc,
          },
        }).json();
        this.name = name?.toUpperCase();
        this.email = email;
        this.total = total;
        this.step += 1;
        this.errors = [];
      } catch ({ response }) {
        await this.manageRequestErrors(response);
      } finally {
        this.loading = false;
      }
    },
    async createInvoice() {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      this.errors = [];
      try {
        const { id } = await ky.post(`${process.env.VUE_APP_BACKEND_URL}/create-invoice`, {
          json: {
            ticketId: this.ticketId,
            rfc: this.rfc,
            name: this.name?.toUpperCase(),
            email: this.email,
            cfdiUse: this.cfdiUse,
            fiscalRegime: this.fiscalRegime,
            zipCode: this.zipCode,
          },
        }).json();
        this.cfdiId = id;
        this.step += 1;
      } catch ({ response }) {
        await this.manageRequestErrors(response);
      } finally {
        this.loading = false;
      }
    },
    async downloadInvoice(format) {
      if (this.loading) return;
      this.loading = true;
      this.downloadContent = null;
      this.downloadFileName = null;
      try {
        const { Content } = await ky.post(`${process.env.VUE_APP_BACKEND_URL}/download-invoice`, {
          json: {
            format,
            cfdiId: this.cfdiId,
          },
        }).json();
        this.downloadFile(format, Content);
      } catch ({ response }) {
        this.manageRequestErrors(response);
      } finally {
        this.loading = false;
      }
    },
    async manageRequestErrors(response) {
      if (response && response.status === 422) {
        const { errors } = await response.json();
        this.errors = errors;
        // this.errors = error.response.body.errors;
      } else if (response && response.status === 400) {
        const { errorMessage } = await response.json();
        this.error = errorMessage;
        // this.errors = error.response.body.errors;
      }
    },
    downloadFile(format, content) {
      const linkSource = `data:application/${format};base64,${content}`;
      this.downloadContent = linkSource;
      const downloadLink = document.createElement('a');
      const fileName = `Factura.${format}`;
      this.downloadFileName = fileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    getErrors(property) {
      return this.errors.filter((el) => el.field === property);
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
html, body {
    height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
#app {
  font-family: "Avenir Next LT Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.nav {
  padding: 18px;
  font-size: .875rem;
  line-height: .875rem;
  font-family: "Avenir Next LT Pro";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 100;
}
.logo-mobile {
  display: none;
  height: 40px;
}
.nav-links {
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.nav-link {
  margin-left: 20px;
}
h1 {
  font-family: "Silver South Serif", Helvetica;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 30px;
}
.container-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 100px;
}
.bg {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 49%;
  min-height: 100%;
  height: 100vh;
  background-color: #fde8dc;
  z-index: -1;
}
.container {
  width: 800px;
  margin-bottom: 30px;
  max-width: 96%;
}
.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}
.column {
  width: 47%;
}
.image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.ticket {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  width: 80%;
  height: auto;
}
.ticket-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.total {
  margin-bottom: 20px;
}
.form {
  display: flex;
  flex-direction: column;
}
.label {
  margin-bottom: 20px;
}
.submit {
  height: 42px;
  background-color: black;
  color: white;
  border-radius: 20px;
  padding-left: 3px;
  transition: background-color .4s cubic-bezier(.32,.24,.15,1),color .4s cubic-bezier(.32,.24,.15,1);
  cursor: pointer;
  border:none;
  font-weight: 500;
  text-transform: uppercase;
  font-size: .9rem;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled {
  opacity: .5
}
.order-link {
  background-color: #e9bc96;
  border-radius: 100px;
  padding: 3px 20px 3px 3px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.order-icon {
  background-color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.steps {
  margin-bottom: 12px;
}
.general-error {
  background-color: rgba(255, 0, 0, 0.185);
  border: red 1px solid;
  color: rgb(221, 0, 0);
  line-height: 1.2;
  font-size: .8rem;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 18px;
}
.ticket-data-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.ticket-data-item {
  width: 48%;
}
.ticket-data-number {
  font-size: 1.4rem;
  margin-bottom: 4px;
}
.contact {
  margin-top: 24px;
  line-height: 1.3;
}
.mail-link:link {
  color: orange;
  text-decoration: none;
}
.download-link {
  color: orange;
  margin-top: 12px;
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .column {
    width: 60%;
  }
  .image {
    width: 36%;
  }
  .ticket {
    width: 90%;
  }
  .bg {
    min-width: 38%;
  }
  .logo {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
}
@media only screen and (max-width: 650px) {
  .row {
    flex-direction: column;
  }
  .container-wrapper {
    padding-top: 80px;
    width: 100%;
  }
  .column {
    width: 100%;
  }
  .bg {
    display: none;
  }
  .image {
    order: 2;
    margin-top: 60px;
  }
  .nav-link {
    display: none;
  }
  .ticket {
    width: 60%;
  }
}
@media only screen and (max-width: 400px) {
  h1 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 370px) {
  h1 {
    font-size: 32px;
  }
}
</style>
