<template>
  <input
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    class="input"
    type="text" >
</template>

<script>
export default {
  props: ['modelValue'],
};
</script>

<style scoped>
.input {
  width: 100%;
  padding: 14px 12px 9px 12px;
  border: 1px dotted #e9bc96;
  border-radius: 5px;
  outline: none;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
</style>
