<template>
  <label>
    <div class="label-text">
      {{ label }}
    </div>
    <slot :hideError="hideError"></slot>
    <div class="errors" v-if="showError && errors.length > 0">
      <div class="error" v-for="(error, index) in errors" :key="index">
        - {{ error.message }}
      </div>
    </div>
  </label>
</template>

<script>
export default {
  data() {
    return {
      showError: false,
    };
  },
  props: {
    errors: Array,
    label: String,
  },
  watch: {
    errors() {
      this.showError = true;
    },
  },
  methods: {
    hideError() {
      this.showError = false;
    },
  },
};
</script>

<style scoped>
.label-text {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  line-height: 1.2;
}
.errors {
  margin-top: 8px;
  color: red;
  font-size: 14px;;
}
.error {
  margin-top: 6px;
}
</style>
