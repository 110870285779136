<template>
<div class="load-wrapper" ref="loadContainer">
  <div class="inputs" v-show="!loading">
    <slot></slot>
  </div>
  <div v-show="loading" class="loader" :style="{ height: `${height}px`  }">
    <img data-v-bf73e672="" src="/loading.png" alt="" class="loading">
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      height: 0,
    };
  },
  props: {
    loading: Boolean,
  },
  mounted() {
    this.height = this.$refs.loadContainer.clientHeight;
  },
};
</script>

<style scoped>
.inputs {
  display: flex;
  flex-direction: column;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loading {
  width: 24%;
  height: auto;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(
      0deg
    );
  }
  100% {
    transform: rotate(
      1turn
    );
  }
}
</style>
